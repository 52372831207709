/* global Component, pannellum */
class img360Component extends Component {

    static name() {
        return "img360Component";
    }

    static componentName() {
        return "img360Component";
    }

    getProps() {
        return ['src','mystyle'];
    }

    data(){
        return {
            mouseIsOver:false
        };
    }

    mounted() {
        return function () {
            let imageUrl = this.src;
            this.$nextTick(()=>{
                pannellum.viewer('panorama', {
                    "type": "equirectangular",
                    "panorama": imageUrl || "https://pannellum.org/images/alma.jpg",
                    "autoLoad": true,
                    "mouseZoom": "fullscreenonly"
                });
            });
        };
    }

    getTemplate() {
        return `<div id="panorama" style="min-height:35rem!important;"></div>`;
    }
}

img360Component.registerComponent();
